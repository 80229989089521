import Button from 'lego-framework/src/components/Button';
import Icon from 'lego-framework/src/components/Icon';
import Modal from 'lego-framework/src/containers/Modal';
import Tabs from 'lego-framework/src/containers/Tabs';
import Tab from 'lego-framework/src/containers/Tab';
import ClickOutside from 'lego-framework/src/directives/click-outside';
import FormInput from 'lego-framework/src/forms/FormInput';
import FormMaskInput from 'lego-framework/src/forms/FormMaskInput';
import FormTextArea from 'lego-framework/src/forms/FormTextArea';
import FormCheckbox from 'lego-framework/src/forms/FormCheckbox';
import FormSelect from 'lego-framework/src/forms/FormSelect';
import FormAjaxSelect from 'lego-framework/src/forms/FormAjaxSelect';
import FormDatePicker from 'lego-framework/src/forms/FormDatePicker';
import DashboardStat from 'lego-framework/src/modules/stats/DashboardStats';

import vSelect from 'vue-select';

import Form from 'lego-framework/src/forms/PostForm';
import SimpleForm from 'lego-framework/src/forms/SimpleForm';

import VueTable from 'lego-framework/src/tables/VueTable';
import VueTableCard from 'lego-framework/src/tables/VueTableCard';

import ErrorIcon from 'lego-framework/src/svg-icons/ErrorIcon';
import SuccessIcon from 'lego-framework/src/svg-icons/SuccessIcon';
import LoadingAnimation from 'lego-framework/src/svg-icons/LoadingAnimation';

import * as validator from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
import { messages } from 'vee-validate/dist/locale/en.json';

import EditButton from 'lego-framework/src/components/EditButton';
import DeleteButton from 'lego-framework/src/components/DeleteButton';
import SaveButton from 'lego-framework/src/components/SaveButton';

import AjaxView from 'lego-framework/src/ajax/AjaxView';

import NotificationsPlugin from 'lego-framework/src/notification';

export default {
    install: function (Vue) {
        Vue.use(ClickOutside);
        Vue.use(NotificationsPlugin);

        Vue.component('btn', Button);
        Vue.component('icon', Icon);
        Vue.component('edit-btn', EditButton);
        Vue.component('delete-btn', DeleteButton);
        Vue.component('save-btn', SaveButton);
        Vue.component('tabs', Tabs);
        Vue.component('tab', Tab);
        Vue.component('modal', Modal);
        Vue.component('dashboard-stat', DashboardStat);

        Vue.component('b-form', Form);
        Vue.component('s-form', SimpleForm);

        Vue.component('validated-input', FormInput);
        Vue.component('validated-textarea', FormTextArea);
        Vue.component('validated-mask-input', FormMaskInput);
        Vue.component('validated-select', FormSelect);
        Vue.component('validated-vue-select', FormSelect);
        Vue.component('validated-date-picker', FormDatePicker);
        Vue.component('validated-checkbox', FormCheckbox);
        Vue.component('validated-ajax-vue-select', FormAjaxSelect);
        Vue.component('v-select', vSelect);

        Vue.component('vue-table', VueTable);
        Vue.component('vue-table-card', VueTableCard);

        Vue.component('error-icon', ErrorIcon);
        Vue.component('success-icon', SuccessIcon);
        Vue.component('error-icon', ErrorIcon);
        Vue.component('loading-animation', LoadingAnimation);

        Vue.component('ajax-view', AjaxView);

        Vue.component('validation-provider', validator.ValidationProvider);
        Vue.component('validation-observer', validator.ValidationObserver);

        Object.keys(rules).forEach(rule => {
            validator.extend(rule, {
                ...rules[rule], // copies rule configuration
                message: messages[rule] // assign message
            });
        });
    }
};
