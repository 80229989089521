import guards from './guards';

const Layout = () => import('../layouts/MainLayout');
const Home = () => import('../views/Home');
const About = () => import('../views/About');

export default {
    path: '/',
    name: 'DashboardLayout',
    redirect: '/app/',
    component: Layout,
    beforeEnter: guards.loggedInGuard,
    children: [
        {
            path: '/',
            name: 'Home',
            component: Home
        },
        {
            path: '/about/',
            name: 'About',
            component: About
        },
        {
            path: '/contact/',
            name: 'Contact',
            component: () => import('../views/ContactUs')
        },
        {
            path: '/faq/',
            name: 'Faq',
            component: () => import('../views/FaqPage')
        },
        {
            path: '/tutorial/',
            name: 'Tutorial',
            component: () => import('../views/TutorialPage')
        },
        {
            path: '/terms-and-conditions/',
            name: 'TermsAndPolicy',
            component: () => import('../views/TermsAndPolicy')
        },
        {
            path: '/privacy-and-policy/',
            name: 'PrivacyAndPolicy',
            component: () => import('../views/PrivacyAndPolicy')
        },
        {
            path: '/our-team/',
            name: 'OurTeam',
            component: () => import('../views/ProfessionalTeamPage')
        },
        {
            path: '/auctions/',
            name: 'Auctions',
            component: () => import('../views/auction/AuctionPage')
        },
        {
            path: '/auctions/:name/',
            name: 'Auctions',
            component: () => import('../views/auction/AuctionPage')
        },
        {
            path: '/auction/:id/details/',
            name: 'AuctionDetails',
            component: () => import('../views/auction/AuctionDetailsPage')
        },
        {
            path: '/services/',
            name: 'ServicesPage',
            component: () => import('../views/ServicesPage')
        },
        {
            path: '/media/:media_type/',
            name: 'AllNewsPage',
            component: () => import('../views/media/AllMediaPage')
        },
        {
            path: '/media-category/:category/',
            name: 'AllMediaPage',
            component: () => import('../views/media/AllMediaPage')
        },
        {
            path: '/media/:id/details/:media_type/',
            name: 'MediaArticlePage',
            props: true,
            component: () => import('../views/media/MediaArticlePage')
        },
        {
            path: '/components/',
            name: 'Components',
            component: () => import('../views/Components')
        }
    ]
};
