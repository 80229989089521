const prod = process.env.NODE_ENV === 'production';

let baseUrl;
if (prod) {
    baseUrl = '/api/';
} else {
    baseUrl = '/api/';
    // baseUrl = '/';
}

const baseCMS = 'cms/';

export default {
    auth: {
        login: baseUrl + 'auth/login/',
        verifyLoginOtp: baseUrl + 'auth/verify-otp/',
        resendLoginOtp: baseUrl + 'auth/resend-otp/',

        status: baseUrl + 'auth/status/',
        OTPSendToMobile: baseUrl + 'auth/customer/user/mobile/',
        OTPVerify: baseUrl + 'auth/customer/user/verify/',

        generateResetLink: baseUrl + 'auth/generate/password-rest/link/',
        resetPassword: baseUrl + 'auth/reset-password/'
    },

    website: {
        interestAddUrl: baseUrl + 'auction/auction-enquiry/add/',

        socialMedia: baseUrl + baseCMS + 'website/social-media/',
        home: baseUrl + baseCMS + 'website/home/',
        aboutUs: baseUrl + baseCMS + 'website/about-us/',
        captcha: baseUrl + 'auth/captcha-get/',
        contactUs: baseUrl + baseCMS + 'website/contact-us/',
        contactUsCreate: baseUrl + baseCMS + 'contact-us/create/',
        team: baseUrl + baseCMS + 'website/team/',
        FAQ: baseUrl + baseCMS + 'website/faq/',

        media: baseUrl + baseCMS + 'website/media/',
        mediaDetails: baseUrl + baseCMS + 'website/media-detail/',

        termsAndConditions: baseUrl + baseCMS + 'website/terms-and-condition/',
        privacyAndPolicy: baseUrl + baseCMS + 'website/privacy-policy/',

        countryOptions: baseUrl + 'master-data/country/vue-select/',
        stateOptions: baseUrl + 'master-data/state/vue-select/',
        districtOptions: baseUrl + 'master-data/district/vue-select/',

        propertyOptions: baseUrl + 'master-data/property-type/vue-select/',
        SubPropertyOptions: baseUrl + 'master-data/property-sub-category/vue-select/',

        formInitialization: baseUrl + 'master-data/registration/form-initials/',

        bankOptions: baseUrl + 'auth/bank/vue-select/',

        userManual: baseUrl + 'cms/user-manual/get-document/'
    },

    register: {
        individual: baseUrl + 'auth/customer/reg/individual/',
        groupIndividual: baseUrl + 'auth/customer/reg/group/',
        proprietor: baseUrl + 'auth/customer/reg/proprietor/',
        partnership: baseUrl + 'auth/customer/reg/partnership/',
        llp: baseUrl + 'auth/customer/reg/llp/',
        private: baseUrl + 'auth/customer/reg/private/',
        society: baseUrl + 'auth/customer/reg/society/'

    },

    auction: {
        requestCallBack: baseUrl + 'auction/third-party-call-back/',
        upcoming: baseUrl + 'auction/up-coming-auction/list/',
        info: baseUrl + 'auction/auction/info/'
    }
};
