const Layout = () => import(/* webpackChunkName: "login" */'../layouts/BlankLayout');
const Login = () => import('../views/auth/Login');

export default {
    path: '/',
    name: 'AuthLayout',
    redirect: '/login/',
    component: Layout,
    children: [
        {
            path: '/login/',
            name: 'Login',
            component: Login
        },
        {
            path: '/verify-mobile/:phone/',
            name: 'VerifyMobile',
            component: () => import('../views/auth/VerifyMobilePage')
        },
        {
            path: '/forgot-password/',
            name: 'ForgotPassword',
            component: () => import('../views/auth/ForgotPassword')
        },
        {
            path: '/set-new-password/:email/',
            name: 'SetNewPassword',
            component: () => import('../views/auth/SetNewPassword')
        },
        {
            path: '/register/',
            name: 'Register',
            component: () => import('../views/auth/register/CreateNewAccount')
        },
        {
            path: '/register-verify-mobile/',
            name: 'RegisterVerifyMobile',
            component: () => import('../views/auth/register/RegisterMobileVerification')
        },
        {
            path: '/register-basic-info/',
            name: 'RegisterBasicInfo',
            component: () => import('../views/auth/register/RegisterBasicInformation')
        },
        {
            path: '/register-contact-info/',
            name: 'RegisterContactInfo',
            component: () => import('../views/auth/register/RegisterContactInformation')
        },
        {
            path: '/register-pan-form/',
            name: 'RegisterPanForm',
            component: () => import('../views/auth/register/RegisterPanForm')
        },
        {
            path: '/register-kyc-documents/',
            name: 'RegisterKycDocuments',
            component: () => import('../views/auth/register/RegisterKycDocuments')
        },
        {
            path: '/register-successful/',
            name: 'RegisterSuccessful',
            component: () => import('../views/auth/register/RegistrationSuccessful')
        },
        {
            path: '/register-error/',
            name: 'RegisterError',
            component: () => import('../views/auth/register/RegistrationErrorPage')
        },
        {
            path: '/logout/',
            name: 'Logout',
            component: () => import('../views/auth/Logout')
        }
    ]
};
